.forms {

    padding: 0.625rem;
    border:1px solid var(--gray-300);
    border-top: 3px solid var(--atlantica-300); 
    min-height: 150px;

    h2, h3, h4 {
        margin-left: 25px;
    }
    b {
        padding-right: 20px;
    } 
    
    form {

        //max-width: 800px;

        div {
            display: flex;
            margin: 10px;    

            span {
                width: 140px;
                text-align: right;
            }
            strong {
                color: crimson;
                font-size: 0.8rem;
                margin: -8px 0;
            }

            label {
                width: 140px;
                text-align: right;
            }
    
            input, select, textarea {
                width: 60%;
                padding: 2px;
                border-radius: 0.25rem;
                border-style: solid;
                border-width: 1px;
            } 
            
            #deadline-status{
                padding-top: 0;
                padding-bottom: 0;
            }
            
            input:hover, select:hover, textarea:hover {
                border: solid var(--atlantica-300);
                border-width: 1px;
                box-shadow: 0px 0px 4px var(--atlantica-300);
            }

            input:focus, select:focus, textarea:focus {
                background: rgba(60, 190, 160, 0.2);
            }
            input:placeholder-shown {
                text-overflow: ellipsis;
            }
            .btn-container {
                margin: 0;
                margin-left: auto;
            }
            
            button { 
                width: 100px;
                margin: 5px;
                text-decoration: none;
                padding: 10px;
                padding-top: 5px;
                padding-bottom: 5px;
                border-radius: 8px;
                border: transparent;
                color: var(--gray-100);  
                padding: 7px;
                transition: 0.3s;

                i{
                    //background-color: lawngreen;
                    // width: 2rem;
                    // height: 1.25rem;
                    margin-left: -.5rem;
                    padding: 0.3rem 1rem 0.3rem 0.5rem;//top right bottom left
                }
            }
            .btn-save  {
                background: var(--atlantica-300);
                color: var(--gray-100);  
            }
            .btn-save:hover {
                background: var(--atlantica-400)
            }
            .btn-cancel  {
                background:var(--atlantica-red);
                color: var(--gray-100);  
            }
            .btn-cancel:hover {
                background:rgb(158, 60, 60);
            }
        }

        .search-form {
            > button {
                background-color: transparent;
                margin: 0;
                margin-left: 10px;
                padding: 0;
                width: 0;
                cursor: pointer;
                text-decoration: none;
                border: none;
                color: var(--gray-800);
            }
        }

        .radio-group {
            margin: 0;
            

            label {
                text-align: start;
            }

            input {
                width: 25%;
                align-self: center;
            }
        }
        
    }

    img {
        display: block;
        cursor: none;
        width: auto;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }
    
    // textarea {
    //     width: 60%;
    //     padding: 2px;
    //     //margin-right: 50px;
    //     //margin-left: 140px;
    //     border-radius: 0.25rem;
    //     border-style: solid;
    //     border-width: 1px;
    // }
}

