.summary {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.75rem;
    margin-bottom: 1rem;

    div {
        background: var(--shape);
        padding: 1.5rem 2rem;
        border: 1px solid  var(--gray-300);
        border-radius: 0.25rem;
        //color: var(--text-title);

        header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        strong {
            display: block;
            margin-top: 1rem;
            font-size: 1.45rem;
            font-weight: 400;
            line-height: 2rem;
            white-space: nowrap;
        }

        &.highlight-background {
            //background: var(--atlantica-300);
            background: var(--atlantica-200);
            //background: rgba(56, 180, 152, 0.8);
            color: whitesmoke;
        }

        img {
            width: 2rem;
            height: 2rem;
        }

    }
    
}