.tables {
    
    padding: 0.625rem;
    border:1px solid var(--gray-300);
    border-top: 3px solid var(--atlantica-300); 
    margin-top: 20px;
    min-height: 300px;
    .table-wrapper {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
    .table-footer {
        //display: none;
        text-align: center;
        margin-top: 0.5rem;
        
        span {
            margin: 0 0.5rem;
        }

        input {
            width: 2rem;
        }

        .footer-button {
            width: auto;
            min-width: 2rem;
            padding: 0.25rem;
            border: 1px solid;
            border-color: var(--gray-200);
            border-radius: .4rem;
            margin: 0 2px;
            //align-items: center;
            line-height: 0.85rem;
            transition: 0.3s;
            &:hover {
                background-color: var(--gray-100);
            }
        }
    }

    table, th, td {
        border:1px solid  var(--gray-300);
        border-collapse: collapse;
        padding: 0.3125rem;
    }
    th, td {
        width: 32%;
        max-width: 135px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;

        &:nth-last-child(3) {//email
            min-width: 100px;
            max-width: 13.1rem;
        }
        &:nth-last-child(2) {//perfil
            text-overflow: ellipsis;
            max-width: 52px;
        }
        &:last-child {
            width: 0%;
        }
    }

    #client-table {
        th, td {
            width: 21.5%;
            max-width: 135px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: clip;
            &:nth-last-child(5) {//email
                min-width: 13.1rem;
            }
            &:nth-last-child(4) {//cnpj
                max-width: 175px;
            }
            &:nth-last-child(2), &:nth-last-child(3) {//endereco / contato
                max-width: 125px;
            }
            &:last-child {
                width: 0%;
            }
        }
    }

    table {
        margin-top: 10px;
        width: 100%;
    }

    h2, h3, h4 {
        margin-left: 50px;
    }

    button {
        text-decoration: none;
        width: 100%;
        background-color: transparent;
        border: none;
        color: var(--gray-800);
    }
}

.table-project {
    
    margin-top: 3rem;

    table {
        width: 100%;
        //border-collapse: collapse;
        //border-spacing: 0 1rem;
    }
    .table-wrapper {
        display: block;
        overflow-x: auto;
    }
    th {
        color: var(--text-body);
        opacity: 0.7;
        font-weight: 600;
        line-height: 1rem;
        //max-width: 2rem;
        //padding: 1rem;
    }
    th, td {
        text-align: center;
        //width: 10%;
        width: 17%;
        padding: 1rem 0.25rem;
        min-width: 2rem;

        &:first-child {
            width: 50%;
            min-width: 9rem;
        }
        &:last-child, &:nth-last-child(2), &:nth-last-child(3) {//buttons
            width: 0%;
            padding: 0.5rem;
        }
        &:last-child {
            padding-right: 1rem;
        }
        &:nth-child(2), &:nth-child(3), &:nth-child(4) {//datas 
            max-width: 180px;
            min-width: 7rem;
        }
    }
    th:last-child, th:nth-last-child(2), th:nth-last-child(3) {        
        button {
            visibility: hidden;
        }
    }
    thead {
        div {
            border: 0;
            margin-bottom: -5px;
        }
    }
    tbody {
        div {
            border: 1px solid  var(--gray-300);
            border-radius: 0.25rem;
            margin-top: 10px;
            width: 100%;
            transition: 0.3s;
        }
        div:hover {
        background: rgba(56, 180, 152, 0.2);
        //box-shadow: rgba(56, 180, 152, 0.2);
        border: 1px solid  var(--atlantica-300);
        }
    }

    #finance-table {
        th, td {
            width: 9%;

            &:first-child {
                width: 50%;
                min-width: 9rem;
            }
            &:last-child, &:nth-last-child(2), &:nth-last-child(3) {//buttons
                width: 0%;
            }
            &:nth-child(4), &:nth-child(5) {//datas
                max-width: 180px;
                min-width: 6.5rem;
                //background-color: #959495;
            }
            &:nth-child(3) {//tipo
                max-width: 180px;
                min-width: 5rem;
                //background-color: #959495;
            }
            &:nth-child(2) {//valor
                max-width: 180px;
                min-width: 7rem;
                //background-color: #959495;
            }
        }
        .td-withdraw {
            //color: var(--atlantica-red);
            //color: red;
            color: rgb(209, 32, 32)
        }
    }

    button {
        text-decoration: none;
        width: 100%;
        background-color: transparent;
        border: none;
        color: var(--gray-800);
    }

    //hide native checkbox
    .checkbox__input {
        input {
          opacity: 0;
          width: 1em;
          height: 1em;
        }
    }
    //Custom Unchecked Checkbox Styles
    .checkbox__control {
        display: inline-grid;
        width: 1em;
        height: 1em;
        border-radius: 0.25em;
        border: 0.1em solid currentColor;
        margin-top: 2px;
        margin-right: 3px;
      }
      .checkbox__input {
        display: grid;
        grid-template-areas: "checkbox";
      
        > * {
          grid-area: checkbox;
          cursor: pointer;
        }
      }
      //Styling :checked vs Unchecked State
      .checkbox__control svg {
        transition: transform 0.1s ease-in 25ms;
        transform: scale(0);
        transform-origin: bottom left;
      }
      .checkbox__input input:checked + .checkbox__control svg {
        transform: scale(1);
      }
      .checkbox__input input:focus + .checkbox__control {
        box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
      }
      //Styles For :disabled Checkboxes
      :root {
        --disabled: #959495;
      }
      .checkbox__input input:checkbox:disabled + .checkbox__control {
        color: var(--disabled);
      }
      .checkbox--disabled {
        color: var(--disabled);
      }

}



