.about {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--atlantica-white);
    p, article {
        border-left: none; /* required for vanilla */
        padding: 0;
        text-align: center;
        display: flex;
        align-self: center;
    }
    .grid {

        z-index: 1;
        align-items: center;
        display: grid;
        //grid-template-columns: repeat(3, 1fr);
        grid-template-columns: auto var(--about-arrow-normal-length) auto;
        margin: 0;
        
        .middle {
            display: block;
            z-index: -1;
            padding: 0;
            height: 0.25rem;
            border: 2px solid var(--atlantica-100);
            border-radius: 0.25rem;
            //box-shadow: 0px 0px 5px var(--atlantica-300);
            .arrow-right {
                position: absolute;
                width: var(--about-arrow-normal-length);
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    height: 0.25rem !important;
                    margin-top: -7px;
                    margin-left: -8px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent transparent transparent var(--atlantica-100);
                }    
            }
            .arrow-left {
                position: absolute;
                width: var(--about-arrow-normal-length);
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 100%;
                    height: 0.25rem !important;
                    margin-top: -7px;
                    margin-right: -4px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent var(--atlantica-100) transparent transparent;
                }    
            }
        }
        .invisible {
            border: 0;
        }
        .vertical {
            grid-column: auto / span 3;
            display: block;
            z-index: -1;
            padding: 0;
            height: var(--about-arrow-normal-length);
            .arrow-left {
                position: absolute;
                width: var(--about-arrow-normal-length);
                &::after {
                    content: "";
                    position: absolute;
                    // top: 100%;
                    right: 100%;
                    height: 0.25rem !important;
                    margin-right: -5px;
                    margin-top: calc(var(--about-arrow-normal-length) - .4rem);
                    border-width: 7px;
                    border-style: solid;
                    border-color: var(--atlantica-100) transparent transparent transparent;
                }    
            }
            .arrow-right {
                position: absolute;
                width: var(--about-arrow-normal-length);
                &::after {
                    content: "";
                    position: absolute;
                    // top: 100%;
                    right: 100%;
                    height: 0.25rem !important;
                    margin-right: -5px;
                    margin-top: calc(var(--about-arrow-normal-length) - .4rem);
                    border-width: 7px;
                    border-style: solid;
                    border-color: var(--atlantica-100) transparent transparent transparent;
                }    
            }
        
        }
        .left {
            border-left: 4px solid var(--atlantica-100);
            border-top: 0;
            border-right: 0;
            border-bottom: 0;
            border-radius: 0.25rem;
            margin: 0;
            margin-left: 20%;
        }
        .right {
            border-left: 4px solid var(--atlantica-100);
            border-top: 0;
            border-right: 0;
            border-bottom: 0;
            border-radius: 0.25rem;
            margin: 0;
            margin-left: 80%;
        }
        // .right {
        //     border-right: 4px solid var(--atlantica-100);
        //     border-top: 0;
        //     border-left: 0;
        //     border-bottom: 0;
        //     border-radius: 0.25rem;
        //     margin: 0;
        //     margin-right: 20%;
        // }
        article {
            font-size: 0.75rem;
            font-style: bold;
            background-color: var(--atlantica-white);
            margin: 0 0;
            padding: 1rem;
            line-height: 1.5rem;
            border: 1px solid  var(--gray-300);
            border-radius: 0.25rem;
            width: 100%;
            //height: auto;
            height: 100%;
            transition: 0.3s;
        }
        article:hover {
            background: rgba(56, 180, 152, 0.2);
            //box-shadow: rgba(56, 180, 152, 0.2);
            border: 1px solid  var(--atlantica-300);
            //box-shadow: 0px 0px 5px var(--atlantica-300);
        }
    }

    img {
        cursor: default;
        //width: auto;
        max-width: 25rem;
        max-height: 25rem;
        min-width: 250px;
        min-height: 250px;
        margin-top: -1.25rem;
    }

    .contact {
        display: flex;
        flex-direction: row;

        div {
            padding: 2px;
            margin: 2px;
            p, b {
                margin: 0px;
                padding: 5px;

            }
            i {
                padding: 2.5px;
                border-radius: 2px;
            }
        }

        a, a:visited, a:hover, a:active {
            text-decoration: none !important;
            color: inherit;
        }
    }

    .fab {
        font-style: bold;
    }
    // .fa-whatsapp {
    //     color: #128C7E;
    // }
    // .fa-instagram {
    //     color: #833ab4;
    // }
    .fa-whatsapp {
        background-color: #25D366;
        color: white;
    }
    .fa-instagram {
        //background-color: #833ab4;
        color: white;
        background: #f09433; 
        background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
        background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
        background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );    
    }

}