.header-position {
    position: fixed;
    z-index: 9;
    width: 100%;
    top: 0%;
}
.sidebar-position {
    position: fixed;
    z-index: 8;
    top: 6rem;
    left: 0px; /* change to 0 when logged and -250px when not logged */
    
    
  }

.main-content {

    //display: flex;
    flex-direction: column;
    //position: fixed;
    //left: 250px; 
    //width: 50%;
    margin-top: 6rem;
    margin-left: var(--sidebar-width-expanded);
    padding: 1.25rem;
    
    //margin-right: 50px;
    max-width: 1000px;
    min-width: 320px;
    
    
}