#page-auth {
    display: flex;
    flex-direction: row;
    //justify-content: space-evenly;
    z-index: 99;
    height: 100vh;
  
    aside {
      flex: 2;
      background: var(--atlantica-white);
      
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      //margin: 50px 50px;
  
      img {
        max-width: 90%;
        max-height: 90%;
        min-width: 320px;
        min-height: 320px;
      }
      strong {
        font: 700 36px Lexend, sans-serif;
        line-height: 42px;
        margin-top: 16px;
      }
      p {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-top: 12px;
      }
    }
  
    main {
      flex: 2;
      background: var(--gray-999);
      //padding: 0 20px;
      //min-width: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .main-login {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 320px;
      margin: 0 20px;
      align-items: stretch;
      text-align: center;
  
      > img {
          max-width: 100px;
          max-height: 100px;
          border-radius: 50%;
          padding: 10px;
          background-color: var(--atlantica-white);
        align-self: center;
      }
  
      h2 {
        font-size: 1.5rem;
        margin: 64px 0 24px;
        font-family: 'Poppins', sans-serif;
      }
  
      form {

        input {
          height: 50px;
          border-radius: 0.25rem;
          padding: 0 16px;
          margin-bottom: 15px;
          background:#FFF;
          border: 1px solid #a8a8b3;
        }
  
        button {
          margin-top: 16px;
        }
  
        button, input {
          width: 100%;
        }
      }
  
      p {
        font-size: 0.875rem;//14px
        color: #737380;
        margin-top: 16px;
  
        a {
          color: #e559f9;
        }
      }
    }

    button {
        width: 90%;
        margin: 10px 0;
        height: 50px;
        border-radius: 8px;
        padding: 10px;
        background: var(--atlantica-300);
        color: var(--gray-100);  
        font-weight: 500;
        border-color: transparent;
        transition: 0.3s;
    }
    
    button:hover {
        background: var(--atlantica-400);
        
      }

    .separator {
       font-size: 0.875rem;
      color: var(--gray-200);
      margin: 32px 0;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        flex: 1;
        height: 1px;
        background: var(--gray-200);
        margin-right: 16px;
      }

      &::after {
        content: '';
        flex: 1;
        height: 1px;
        background: var(--gray-200);
        margin-left: 16px;
      }
    }

    a {
        margin-bottom: 10px;
        
        color: var(--gray-200);
    }

    .remember-me {
        
        display: inline-flex;
        width: 50%;
        text-align: start;
        
        p {
            flex: 4;
            margin-top: 0;   
            color: var(--gray-200);
            
            border-left: none; /* required for vanilla */
            margin-left: 0px; /* required for vanilla */
            padding: 0; /* required for vanilla */
        }
        
        input {
            flex: 1;            
            max-height: 12px;
            margin-top: 3px;
        }

        
    }
  }
  
 