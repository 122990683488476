.header-container {
    background: var(--atlantica-white);
    height: 6rem;
    display: flex;
    align-items: center;
    //padding: 2rem 4rem;
    padding: 1rem 2rem;
    border-bottom: 2px solid var(--gray-150);
    
    p {
        font-size: 1.25rem;
        font-family: Lexend;
        font-weight: 500;
        margin-left: 1.75rem;
        padding: 0.25rem 0.25rem 0.25rem 1.75rem; /* up right down left */
        border-left: 2px solid var(--gray-200);
        text-align: center;
    }

    .display-user {
        font-size: 0.875rem;
        font-family: Lexend;
        font-weight: 400;
        margin-right: 1.75rem;
        padding: 0.25rem 1.75rem 0.25rem 0; /* up right down left */
        border-right: 2px solid var(--gray-200);
        margin-left: auto;
        //display: block;
        //align-items: center;
        //align-content: center;
        text-align: center;
    }

    span {
        margin-left: auto;
        text-transform: capitalize;
        font-size: 1rem;
    }

    img {
        cursor: pointer;
        width: 4rem;
    }

    button {
        background-color: var(--atlantica-300);
        color: var(--atlantica-white);
        //margin-left: auto;
        text-transform: capitalize;
        border-radius: 5px;
        font-size: 0.875rem;
        //padding: 4px 20px;
        padding: 4px 1.25rem;
        //padding: 0.25rem 1.25rem;
        border: none;
        transition: 0.3s;
    }
    button:hover {
        background: var(--atlantica-400);
        color: var(--gray-100); 
    }
}
