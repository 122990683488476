.sidebar {
  background: var(--gray-999);
  //transition: all 0.5s ease;
  height: 100vh;
  width: var(--sidebar-width-expanded);
  padding-top: 30px;
  //text-align: center;

  i {
    //padding-right: 5px;
    //margin-right: 10px;
    min-width: 30px;
  }
  a {
    color: var(--gray-100);
    display: block; /* empilha os itens do menu */
    width: 100%;
    line-height: 70px;
    text-decoration: none; /* tira o underline */
    padding-left: 35px;
    box-sizing: border-box;
    transition: 0.3s;
  
    div {
      display: inline;
    }  
  }

  a:hover {
    background: var(--atlantica-500);
  }
}