.search {
        
    margin: 20px;
    margin-left: 10px ;
    //display: flex;
    //justify-content: space-between;
    
    input {
        width: 60%;
        padding: 1px;
        border-radius: 0.25rem;
        border-style: solid;
        border-width: 1px;
    }
    input:hover {
        border: solid var(--atlantica-300);
        border-width: 1px;
        box-shadow: 0px 0px 4px var(--atlantica-300);
    }

    > label {
        margin-right: 10px;
    }
    > button {
        margin-left: 10px;
        width: 0;
        cursor: default;
        text-decoration: none;
        background-color: transparent;
        border: none;
        color: var(--gray-800);
    }
}
