.react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.react-modal-content {
    width: 100%;
    max-width: 600px;
    background: var(--atlantica-white);
    padding: 2.5rem;
    position: relative;
    border-radius: 0.25rem;
}

.react-modal-content-remember {
    width: 100%;
    //max-height: 300px;
    max-width: 500px;
    background: var(--atlantica-white);
    padding: 2.5rem;
    position: relative;
    border-radius: 0.25rem;
}

.react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background: transparent;
    
    transition: filter 0.2s;

    &:hover {
        filter: brightness(0.8);
    }
}

.react-modal-table {

    //border:1px solid var(--gray-300);
    //border-top: 3px solid var(--atlantica-300); 
    margin-top: -15px;
    min-height: 250px;

    .table-wrapper {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
    
    table, th, td {
        border:1px solid  var(--gray-300);
        border-collapse: collapse;
        padding: 5px;
    }

    th, td {
        width: 32%;
        max-width: 115px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        // &:last-child {
        //     width: 0%;
        // }
    }

    table {
        width: 100%;
    }

    h2, h3, h4 {
        margin-left: 50px;
    }

    .table-footer {
        //display: none;
        text-align: center;
        margin-top: 0.5rem;
        
        span {
            margin: 0 0.5rem;
        }

        input {
            width: 2rem;
        }

        .footer-button {
            width: auto;
            min-width: 2rem;
            padding: 0.25rem;
            border: 1px solid;
            border-color: var(--gray-200);
            border-radius: .4rem;
            margin: 0 2px;
            //align-items: center;
            line-height: 0.85rem;
            transition: 0.3s;
            &:hover {
                background-color: var(--gray-100);
            }
        }
    }
}


.react-modal-form {

    //border:1px solid var(--gray-300);
    //border-top: 3px solid var(--atlantica-300); 
    //margin-top: -15px;
    min-height: 250px;
    
    b {
        padding-right: 20px;
    } 
    
    form {

        margin-top: 20px;

        #react-modal-simple-form {        
            // display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 225px;
            margin: 0;
            //background-color: lightblue;
            width: auto;
            height: 100%;

            input {
                margin-top: 10px;
                margin-bottom: 10px;
                width: 98%;
            }
        }

        div {
            display: flex;
            margin: 10px;    

            span {
                width: 100px;
                text-align: right;
            }
            strong {
                color: crimson;
                font-size: 0.8rem;
                margin: -8px 0;
            }

            label {
                width: 100px;
                text-align: right;
            }
    
            input, select {
                //position: absolute;
                //right: 0;
                width: 75%;
                padding: 2px;
                //margin-right: 50px;
                border-radius: 0.25rem;
                border-style: solid;
                border-width: 1px;
            }   
            input:hover, select:hover {
                border: solid var(--atlantica-300);
                border-width: 1px;
                box-shadow: 0px 0px 4px var(--atlantica-300);
            }

            .btn-container {
                margin: 0;
                margin-left: auto;
            }
            
            button { 
                width: 100px;
                margin: 5px;
                text-decoration: none;
                padding: 10px;
                padding-top: 5px;
                padding-bottom: 5px;
                border-radius: 8px;
                border: transparent;
                color: var(--gray-100);  
                padding: 7px;
                transition: 0.3s;
            }
            .btn-save  {
                background: var(--atlantica-300);
                color: var(--gray-100);  
            }
            .btn-save:hover {
                background: var(--atlantica-400)
            }
            .btn-cancel  {
                background:var(--atlantica-red);
                color: var(--gray-100);  
            }
            .btn-cancel:hover {
                background:rgb(158, 60, 60);
            }
        }
    }

    

}