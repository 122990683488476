* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {  
    /* collors */
    --gray-50: #F7F8FA;
    --gray-100: #E6E8EB;
    --gray-150: #cfcfcf;
    --gray-200: #AFB2B1;
    --gray-300: #a0a3a2;
    --gray-400: #9e9e9e;
    --gray-450: #808080;
    --gray-500: #707070;
    --gray-600: #5c5c5c;
    --gray-700: #525252;
    --gray-800: #494D4B;
    --gray-999: #1f1d1e;
  
    --green-500: #04D361;
    
    --atlantica-white: #ffffff;
    --atlantica-gray: #777777;
    --atlantica-black: #0a0a0a;
    --atlantica-red: rgb(182, 69, 69);

    --atlantica-50: #61e9c7;
    --atlantica-100: #41b49b;
    --atlantica-main: #38b498;
    --atlantica-200: #20aa87;
    --atlantica-300: #188369;
    --atlantica-400: #146954;
    --atlantica-500: #0e4739;
    --atlantica-600: #102b24;
    --atlantica-700: #0d241e;

    /* measures */
    --sidebar-width-expanded: 220px;
    --sidebar-width-collapsed: 100px;
    --about-arrow-normal-length: 4rem;
    --about-arrow-small-length: 3rem;
    --about-arrow-tiny-length: 2rem;
}

.off {
    visibility: hidden;
    /*transition: all 0.5s ease;*/
}

html {
    min-width: 320px;
    min-height: 640px;
}

body {
    
    background: var(--atlantica-white);
    /*TODO remove this section for water mark
    background-image: url("../src/logo-short.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: 500px;
    background-position-x: 500px; */
}

body, input, textarea, button {
    font: 500 1rem Inter, sans-serif;
    color: var(--gray-700);
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    font-family: Lexend, sans-serif;
    color: var(--gray-800);
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.5rem;
}

button {
    cursor: pointer;
}

.toast-success {
    background-color: black;
}