//@media handheld and (min-width: 20em), screen and (min-width: 20em) { ... }

/* default html font-size is 16px 

32px    -   2rem
24px    -   1.5rem
22px    -   1.375rem
20px    -   1.25rem
18px    -   1.125rem
16px    -   1rem
14px    -   0.875rem
12px    -   0.75rem
10px    -   0.625rem
8px     -   0.5rem
5px     -   0.3125rem
4px     -   0.25rem
*/

@media (max-width: 1080px) {
    html {
        font-size: 100%;
    }
    /* MODAL */
    .react-modal-content {
        margin-left: var(--sidebar-width-expanded);
    }
}

@media (max-width: 960px) {
    //*** FINANCE SUMMARY ***
    .summary {
        gap: 1rem !important;
        div {
            padding: 1rem 1rem !important;
            strong {                
                font-size: 1.25rem !important;
            }    
        }
    }
    //*** PROJECT TABLE ***
    .table-project {
        th, td {
            width: 12% !important;
            //padding: 1rem 0.25rem;
            //min-width: 2rem;
            &:first-child {
                width: 50% !important;
            }
            &:last-child, &:nth-last-child(2), &:nth-last-child(3) {//buttons
                width: 0% !important;
            }
            &:nth-child(2), &:nth-child(3), &:nth-child(4) {//datas 
                max-width: 180px;
                min-width: 6rem !important;
            }
        }
    }
}

//***** TABLET *****
@media (max-width: 768px) {
    html {
        font-size: 87.5%;
    }
    // *** MAIN *** 
    .main-content { 
        margin-left: var(--sidebar-width-collapsed);
    }
    /* MODAL */
    .react-modal-content {
        margin-left: var(--sidebar-width-collapsed);
    }
    // *** LOGIN ***
    aside {
        //TODO: transition
        //z-index: -1;
        //background: var(--gray-999) !important;
        display: none !important;
        //margin-left: -160px;
        //visibility: hidden;
        //transition: all 0.5s ease 0s;
        transition: all 250ms linear 0s;
        //transition-delay: 0.5s;
        img {
            opacity: 0;
        }
    }
    // *** HEADER ***
    .header-container {
        p {
            padding: 0.25rem 0.25rem 0.25rem 1.5rem !important; /* up right down left */
        }
        .display-user {
            padding: 0.25rem 1.5rem 0.25rem 0 !important; /* up right down left */
        }
    }
    // *** SIDEBAR ***
    .sidebar {
        width: var(--sidebar-width-collapsed) !important;
        i {
            transition: all 0.5s ease 0s;
            font-size: 1.4rem;
        }
    }

}
@media (max-width: 768px) and (min-width: 541px) {
   // *** SIDEBAR *** tooltip:
   .sidebar {
    .tooltip {
        position: relative;
        z-index: 1;
        left: 45px;
        line-height: 15px;
        .tooltiptext {
            visibility: hidden;
            background-color: var(--gray-999);
            border-radius: 6px;
            padding: 7px 10px;
            /* Position the tooltip */
            position: absolute;
            z-index: 1;
            top: -5px;
            left: 105%;
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                right: 100%;
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent black transparent transparent;
            }
        }
    }
    a:hover .tooltiptext {
        visibility: visible;
        transition: .25s all ease;
        transition-delay: .3s;
    }
} 
}
@media (max-width: 590px) {
    //*** FINANCE SUMMARY ***
    .summary {
        grid-template-columns: repeat(2, 1fr) !important;
        div {
            &:last-child {
                grid-column: auto / span 2;
            }
        }
    }
}
//***** SMARTPHONE *****
@media (max-width: 540px) {
    // *** MAIN *** 
    .main-content { 
        margin-left: 0;
    }
    /* MODAL */
    .react-modal-content {
        margin-left: 0;
    }
    //*** HEADER ***
    .header-container {
        padding: 0.5rem 1.5rem 0.5rem 6rem !important; /* up right down left */
        p {
            font-size: 1.25rem !important;
            padding-left: 0 !important;
            margin-left: 0.75rem !important;
            border: none !important;
        }
        .display-user { display: none;}
        button {
            margin-left: auto;
        }
    }
    //*** FORM ***
    .forms {
        h2, h3, h4 {
            margin-left: 10px !important;
        }
        form {
            div {
                label {
                    width: 100px !important;
                }
                input, select, textarea {
                    width: 70% !important;
                }
            }
        }
    }
    //*** SIDEBAR ***
    .one, .two, .three {
        background-color: var(--gray-999);
        height: 5px;
        width: 100%;
        margin: 6px auto;
        transition: 0.3s;
    }
    .menu-toggle {
        width: 40px;
        height: 30px;
        margin: 10px;
    }
    .sidebar {
        display: none;
        width: 100vw !important;
        background: transparent;
        font-size: 2rem;
        padding-top: 100px !important;
        a {
            padding: 15px 0px !important;
        }
        i {
            display: none;
            opacity: 0;
        }
        span {
            opacity: 1;
            display: inline;
        }
        .tooltip {
            .tooltiptext {
                visibility: visible;
            }
        }
    }
    .sidebar-position {
        //position: fixed;
        z-index: 10;
        top: 1.15rem;
        left: 10px; 
      }
    .sidebar-position.on {

        .sidebar {
            display: inherit;
        }
        .one, .two, .three {
            background-color: #fff;
        }
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: var(--gray-999);
        z-index: 10;
        text-align: center;

        nav ul {
            text-align: center;
        }
        nav ul a {
            transition-duration: 0.5s;
            font-size: 3rem;
            line-height: 4rem;
            display: block;
        }
        .menu-toggle {
            position: absolute;
            left: 20px;
            top: 15px;
            .one {
                transform: rotate(45deg) translate(7px, 7px);
            }
            .two {
                opacity: 0;
            }
            .three {
                transform: rotate(-45deg) translate(8px, -9px);
            }
        }
    }
    //*** PROJECT TABLE ***
    .table-project { 
        th, td {
            padding: 0.875rem 0.25rem !important;
            &:last-child, &:nth-last-child(2), &:nth-last-child(3) {//buttons
                padding: 0.25rem !important;
            }
            &:last-child {
                padding-right: 0.5rem;
            }
            &:nth-child(2), &:nth-child(3) {//data de conclusao
                min-width: 5.5rem !important;
            }
        }
    }

    //*** FINANCE SUMMARY ***
    .summary {
        //gap: 0.875rem !important;
        grid-template-columns: repeat(2, 1fr) !important;
        //grid-template-columns: auto !important;
        div {
            &:last-child {
                grid-column: auto / span 2;
            }
            //padding: 0.875rem 0.875rem !important;
            strong {                
                font-size: 1.15rem !important;
            }    
        }
    }

    // *** ABOUT *** 
    .about {
        .grid {
            display: block !important;
            .middle {
                margin-left: 50% !important;
                height: var(--about-arrow-small-length) !important;
                width: 0.25rem;
                .arrow-right {
                    &::after {
                        margin-left: -63px !important;
                        margin-top: calc(var(--about-arrow-small-length) - .6rem) !important;
                        border-width: 7px !important;
                        border-color: var(--atlantica-100) transparent transparent transparent !important;
                    }
                }
                .arrow-left {
                    &::after {
                        margin-right: -7px !important;
                        margin-top: calc(var(--about-arrow-small-length) - .6rem) !important;
                        border-width: 7px !important;
                        border-color: var(--atlantica-100) transparent transparent transparent !important;
                    }
                }
            }
            .vertical {
                height: var(--about-arrow-small-length) !important;
                .arrow-left {
                    width: var(--about-arrow-small-length) !important;
                    &::after {
                        margin-top: calc(var(--about-arrow-small-length) - .5rem) !important;
                    }
                }
                .arrow-right {
                    width: var(--about-arrow-small-length) !important;
                    &::after {
                        margin-top: calc(var(--about-arrow-small-length) - .5rem) !important;
                    }
                }
            }
            .left {
                margin-left: 50% !important;
            }
            .right {
                margin-left: 50% !important;
            }   
        }
    }
}

@media (max-width: 450px) {
    /*** table footer ***/
    .tables {
        .table-footer {
            .span1 {
                content: "test" !important;
            }
            span {
                margin: 0 0.3rem !important;
            }
            .divisor {
                display: none !important;
            }
            .footer-button {
                min-width: 1.5rem !important;
                padding: 0.25rem;
                margin: 0 1px !important;
            }
        }
    }
    /*** modal table ***/
    .react-modal-table {
        .table-footer {
            .span1 {
                content: "test" !important;
            }
            span {
                margin: 0 0.3rem !important;
            }
            .divisor {
                display: none !important;
            }
            .footer-button {
                min-width: 1.5rem !important;
                padding: 0.25rem;
                margin: 0 1px !important;
            }
        }
    }
}
@media (max-width: 320px) {
    html {
        font-size: 75%;
    }
    //*** FORM ***
    .forms {
        b {
            //padding-right: 0px !important; 
        } 
        form {
            div {
                label {
                    width: 50px !important;
                }
            }
        }
    }
    //*** FINANCE SUMMARY ***
    .summary {
        gap: 0.875rem !important;
        grid-template-columns: repeat(1, 1fr) !important;
        div {
            padding: 0.875rem 0.875rem !important;
            strong {                
                font-size: 1.15rem !important;
            }    
        }
    }
    // *** ABOUT *** 
    .about {
        .grid {
            .middle {
                height: var(--about-arrow-tiny-length) !important;
                .arrow-right {
                    &::after {
                        margin-left: -55px !important;
                        margin-top: calc(var(--about-arrow-tiny-length) - .6rem) !important;
                    }
                }
                .arrow-left {
                    &::after {
                        margin-top: calc(var(--about-arrow-tiny-length) - .6rem) !important;
                    }
                }
            }
            .vertical {
                height: var(--about-arrow-tiny-length) !important;
                .arrow-left {
                    width: var(--about-arrow-tiny-length) !important;
                    &::after {
                        margin-top: calc(var(--about-arrow-tiny-length) - .5rem) !important;
                    }
                }
                .arrow-right {
                    width: var(--about-arrow-tiny-length) !important;
                    &::after {
                        margin-top: calc(var(--about-arrow-tiny-length) - .5rem) !important;
                    }
                }
            }
        }
    }
}
// *** LANDSCAPE ***
// @media (min-width: 320px) and (max-width: 540px) and (orientation: landscape) {
//     html {
//       transform: rotate(-90deg);
//       transform-origin: left top;
//       width: 100vh;
//       overflow-x: hidden;
//       position: absolute;
//       top: 100%;
//       left: 0;
//     }
//   }